//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-300:_3965,_3980,_9164,_6264,_544,_1584,_7612,_7432;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-300')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-300', "_3965,_3980,_9164,_6264,_544,_1584,_7612,_7432");
        }
      }catch (ex) {
        console.error(ex);
      }